<template>
  <div class="w-full">
    <div class="w-full">
      <Card class="p-5 pt-0">
        <CardFooter
          reloadcard
          showcalendar
          @searchResult="handleSearch($event)"
          @reload="reload"
        />
      </Card>
    </div>
    <div class="w-full h-full p-3" />
    <div style="height: 100%" v-if="isLoading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else>
      <Table
        :headers="header"
        :items="tableData"
        v-if="tableData.length > 0 || loadingTable"
        aria-label="advance Table"
        class="w-full px-0"
        :has-checkbox="false"
        :has-number="false"
        :pagination-list="metaData"
        @page="handlePage($event)"
        @itemsPerPage="handleItemsPerPage($event)"
        page-sync
        :loading="loadingTable"
      >
        <template v-slot:item="{ item }">
          <div
            v-if="item.year"
            class="font-semibold text-sm leading-5 tracking-widest"
          >
            <span class="text-darkPurple">{{ item.data.year }}</span>
          </div>
          <div
            v-else-if="item.description"
            class="font-semibold text-sm leading-5 tracking-widest"
          >
            <span class="text-darkPurple">{{ item.data.description }}</span>
          </div>
          <div
            v-else-if="item.employees"
            class="font-semibold text-sm leading-5 tracking-widest"
          >
            <div class="flex flex-col justify-start items-start">
              <span class="text-darkPurple">{{ item.data.employees }}</span>
            </div>
          </div>
          <div v-else-if="item.status">
            <Badge
              :label="item.data.status"
              variant="primary"
              :background-color="
                item.data.status === 'active'
                  ? 'rgba(19, 181, 106, 0.15)'
                  : 'rgba(135, 142, 153, 0.15)'
              "
              :color="item.data.status === 'active' ? '#13B56A' : '#878E99'"
              class="capitalize"
              style="
                margin-right: 12px;
                font-size: 14px;
                padding: 12px 10px;
                height: 35px;
                border-radius: 5px;
              "
            />
          </div>
          <div v-else-if="item.id" class="cursor-pointer">
            <div
              @click="
                $router.push({
                  name: 'EssAprraisalsDirectReports',
                  params: { id: item.data.id, year: item.data.year },
                })
              "
            >
              <Icon
                icon-name="icon-eye"
                class-name="text-blueCrayola"
                size="xs"
              />
            </div>
          </div>
        </template>
      </Table>

      <div v-else>
        <div class="w-full flex flex-col justify-center items-center mt-20">
          <icon icon-name="empty_agreement" size="l" style="width: 300px" />
          <div class="w-1/2 text-base text-center">
            No performance appraisal displayed.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import CardFooter from "@/components/CardFooter";
import paramsMixin from "@/utilities/paramsMixin";
import timeFormatMixin from "@/utilities/timeFormatMixin";

export default {
  name: "TDirectReports",
  mixins: [paramsMixin, timeFormatMixin],
  components: {
    Table,
    Card,
    CardFooter,
    Badge,
  },
  data() {
    return {
      paginationList: {
        page: 1,
        lastPage: 1,
        total: 1,
        from: 1,
        to: 1,
      },
      loadingTable: true,
      header: [
        { title: "financial year", value: "year" },
        { title: "description", value: "description" },
        { title: "Period", value: "periodStart" },
      ],
      tableData: [],
      isLoading: true,
      employeeLength: "",
      appraisalsArr: [],
      searchQuery: "",
      metaData: {},
      itemsPerPage: null,
      numberOfPage: null,
    };
  },
  methods: {
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },

    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },

    queryUpdate() {
      this.loadingTable = true;
      const search = this.searchQuery ? `&${this.searchQuery}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getOrganizationGoal(`?${pageNumber}${itemPage}${search}`);
    },

    handleSearch(value) {
      this.searchQuery = `search=${value}`;
      this.loadingTable = true;
      this.getOrganizationGoal(`?${this.searchQuery}`);
    },

    percentage(value, total) {
      return Math.round((value / total) * 100);
    },

    reload() {
      if (this.tableData.length > 0) {
        this.loadingTable = true;
        this.tableData = [];
      } else {
        this.isLoading = true;
      }
      this.getOrganizationGoal('');
    },

    getDirectReportsAppraisal() {
      this.$_getDirectReportAppraisals(this.$AuthUser.id).then((response) => {
        const appraisals = response.data.sharedAppraisals;

        if (appraisals.length > 0) {
          this.getAppraisalCycle();
        } else {
          this.isLoading = false;
          this.loadingTable = false;
        }
      });
    },

    getAppraisalCycle() {
      this.$_getAppraisalCycle(this.appraisalQuery()).then((response) => {
        const appraisal = response.data.AppraisalCycle;
        const apprArray = [];
        appraisal.map((appraisalCycles) => {
          const startDate = `${appraisalCycles.cycle_start_date}`;
          const endDate = `${appraisalCycles.cycle_end_date}`;
          apprArray.push({
            periodStart: `${this.formatDateTime(
              startDate
            )} - ${this.formatDateTime(endDate)}`,
          });
          appraisalCycles.cycles.map((cycle) => {
            this.header.push({
              title: cycle.name,
              value: cycle.name,
            });
            apprArray.push({
              [cycle.name]: `${this.formatDateTime(
                cycle.appraisal_starts
              )} - ${this.formatDateTime(cycle.appraisal_ends)}`,
            });
            return {};
          });
          this.appraisalsArr.push(
            apprArray.reduce((r, i) => Object.assign(r, i), {})
          );
          this.header.push(
            { title: "Employees", value: "employees" },
            { title: "Status", value: "status" },
            { title: "", value: "id", image: true }
          );
          return {};
        });
        this.getOrganizationGoal('');
      });
    },

    getOrganizationGoal(params) {
      this.shortTermGoals = [];
      this.tableData = [];
      this.$_getOrganizationGoal(params)
        .then((response) => {
          const goalData = response.data.goals;
          this.metaData = response.data.meta;
          const currentYear = new Date().getFullYear();

          if (goalData.length > 0) {
            const arr = [];
            goalData.forEach((goals) => {
              if (Number(goals.goal.year) === currentYear) {
                arr.push({
                  id: goals.goal.id,
                  year: goals.goal.year,
                  description:
                    goals.goal.goal_performance_templates.agreement_title,
                  employees: this.employeeLength,
                  status: "active",
                });
              } else {
                arr.push({
                  id: goals.goal.id,
                  year: goals.goal.year,
                  description:
                    goals.goal.goal_performance_templates.agreement_title,
                  employees: this.employeeLength,
                  status: "closed",
                });
              }
              return {};
            });

            if (arr.length > 0) {
              arr.push(
                this.appraisalsArr.reduce((r, i) => Object.assign(r, i), {})
              );
              this.tableData.push(
                arr.reduce((r, c) => Object.assign(r, c), {})
              );
            }

            this.isLoading = false;
            this.loadingTable = false;
          }
        })
        .then(() => {
          this.isLoading = false;
          this.loadingTable = false;
        });
    },

    getUsersDirectReports() {
      this.$_getManagerDirectReport(this.$AuthUser.id).then((result) => {
        this.employeeLength = result.data.directReport.length;
      });
    },
  },
  mounted() {
    this.getDirectReportsAppraisal();
    this.getUsersDirectReports();
  },
};
</script>
<style scoped>
.font-size-10 {
  font-size: 10px;
}
</style>
